import {Controller} from "stimulus"
import $ from 'jquery';
import {setSessionVar} from './sessionStorage_controller'
import {get_popup} from './popup_controller'
require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")
import Swal from 'sweetalert2/dist/sweetalert2'
import 'sweetalert2/dist/sweetalert2.css'
import '@sweetalert2/theme-borderless/borderless.scss'


String.prototype.isEmpty = function () {
    return (this.length === 0 || !this.trim());
};

export let validate_email = (email) => new Promise((resolve, reject) => {
    let success = false;
    let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    //Se muestra un texto a modo de ejemplo, luego va a ser un icono
    if (emailRegex.test(email)) {
        success = true;
    }

    resolve(success);
})

export let validate = (obj) => new Promise( (resolve, reject) => {

    let target = $('#'+obj);
    let required_field_input = target.find('input.required');
    let required_field_select = target.find('select.required');
    let required_field_checkbox = target.find('checkbox.required');
    let error = 0;

    let date_collect = $('#date_collect');
    let date_delivery = $('#date_delivery');


    required_field_input.each((index, elem) => {
        let field = $(elem)[0];
        let helper = $(field.parentNode).find('.helper-text');


        if (field.value.isEmpty()) {
            helper.html('<p class="text text-danger">Este campo no puede estar vacio</p>');
            field.classList.remove('is-valid');
            field.classList.add('is-invalid');
            error++;
        } else {
            if(!field.classList.contains('datepicker')){
                field.classList.remove('is-invalid');
                field.classList.add('is-valid');
                helper.html('');
            }
        }
    });

    required_field_select.each((index, elem) => {
        let field = $(elem)[0];
        let helper = $(field.parentNode).find('.helper-text');
        if (field.value.isEmpty()) {
            helper.html('<p class="text text-danger">Este campo no puede estar vacio</p>');
            $(field.parentNode).addClass("is-invalid");
            $(field.parentNode).removeClass("is-valid");
            error++;
        } else {
            // helper.html('');
            $(field.parentNode).removeClass("is-invalid");
            $(field.parentNode).addClass("is-valid");
            // helper.html('');
        }
    });

    required_field_checkbox.each((index, elem) => {
        let checkbox = $(elem)[0];
        let helper = $(checkbox).find('.helper-text');
        if (checkbox.value.isEmpty()) {
            helper.html('<p class="text text-danger">Este campo no puede estar vacio</p>');
            $(checkbox).addClass("is-invalid");
            $(checkbox).removeClass("is-valid");
            error++;
        } else {
            helper.html('');
            $(checkbox).removeClass("is-invalid");
            $(checkbox).addClass("is-valid");
            // helper.html('');
        }
    });


    if(date_collect.val() !== undefined && date_delivery.val() !== undefined){
        let datepicker = $('.datepicker');
        if(!date_collect.val().isEmpty() && !date_delivery.val().isEmpty()){
            if(new Date(date_collect.val()) >= new Date(date_delivery.val())){
                error++;

                datepicker.each((index, elem) => {
                    let date = $(elem)[0];
                    let helper_date = $(date.parentNode).find('.helper-text');

                    helper_date.html('<p class="text text-danger">La fecha de recogida no puede ser mayor o igual que la fecha de entrega.</p>');
                    date.classList.remove('is-valid');
                    date.classList.add('is-invalid');


                });
            }else{
                datepicker.each((index, elem) => {
                    let date = $(elem)[0];
                    // let helper_date = $(date.parentNode).find('.helper-text');

                    // helper_date.html('');
                    date.classList.remove('is-invalid');
                    date.classList.add('is-valid');
                });
            }
        }

    }

    if(error === 0){
        resolve({
            next: true
        })
    }else{
        resolve({
            next: false
        })
    }
})

let show_notify = () => new Promise((resolve, reject) => {

    get_popup('with_rent').then(
        with_rent => {
            Swal.fire({
                title: with_rent.title || 'Proceso de pago',
                text: with_rent.description || "Estimado cliente usted cuenta con un proceso de renta activo, ¿Desea continuar con este proceso y desechar el antiguo?",
                icon: with_rent.icon || 'question',
                showCancelButton: true,
                confirmButtonText: `<i class="fa fa-thumbs-up"></i> ${I18n.t('global.popup.with_rent_confirm')}`,
                cancelButtonText: `<i class="fa fa-thumbs-down"></i> ${I18n.t('global.popup.with_rent_cancel')}`,
                reverseButtons: true,
                allowOutsideClick: true,
                // backdrop: `#354052`,
                backdrop: `rgba(255,255,255,1)`,
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-secondary margin-right-15'
                },
                buttonsStyling: false
            }).then( async (result) => {
                if (result.isConfirmed) {
                    await setSessionVar("shopping_cart","","delete", false);
                    await setSessionVar("has_reservation","","delete", false);
                    await setSessionVar("reservation_time","","delete", false);
                    let reservation_div = $('#reservation');
                    reservation_div.addClass('hidde_reservation');
                    reservation_div.removeClass('show_reservation');
                    $('#show_reservation').css({'display': 'none'});
                    resolve({
                        let_continue: true
                    })

                }
                if(result.isDismissed){
                    resolve({
                        let_continue: false
                    })

                }
            })
        }
    )

})

let check = () => new Promise((resolve, reject) => {
     setSessionVar('inPayProccess','false','add',false).then(
         inPayProcess => {
             if(inPayProcess.success){
                 setSessionVar('has_reservation',true,'add',false).then(
                     result => {
                         if(result.success){
                             resolve({next: true})
                         }else{
                             console.log(result);
                         }
                     }
                 )
             }else{
                 console.log(inPayProcess);
             }
         }
     )


})




export default class extends Controller {

    initialize() {
    }

    validate(e) {
        e.preventDefault();
        let form = e.target.dataset.form;
        let next = validate(e.target.dataset.validate);
        if ( next.next ){
            check(form).then();
        }
    }

    validateCheck(e) {
        e.preventDefault();
        let form = e.target.dataset.form;
        let next = validate(e.target.dataset.validate).then(
            validate => {
                if(validate.next){
                    $('#'+form).submit();
                }
            }
        );
    }

    validate_emails(e) {
        let value = $.trim(e.target.value);

        let emails = value.split(',').map((email) => {
            return $.trim(email);
        }).filter((email) => {
            return email !== '';
        });

        let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        let helper_email_error = [];
        let isValid_emails = [];
        emails.map(email => {
            if (emailRegex.test(email)) {
                isValid_emails.push(true);
            } else {
                helper_email_error.push(email);
                isValid_emails.push(false);
            }
        })

        let isValid = isValid_emails.every(valor => {
            return valor === true;
        })

        if (!isValid) {
            $(`#${e.target.dataset.validate}`).attr('disabled', 'disabled');
            e.target.classList.add('is-invalid');
            $(`#${e.target.dataset.helper}`).text(`Correos que no cumplen con los requisitos: ${helper_email_error.join(', ')}`)
        } else {
            $(`#${e.target.dataset.validate}`).removeAttr('disabled');
            e.target.classList.remove('is-invalid');
            $(`#${e.target.dataset.helper}`).text('')
            helper_email_error = []
            // console.log(e.target);
        }
    }
}