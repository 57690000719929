import consumer from "./consumer"
import $ from 'jquery';
document.addEventListener('turbo:load', () => {
    if (location.pathname.includes('/admin')) {
        let body = document.getElementById('user_notification_id');
        if (body !== null) {
            let user_id = body.dataset.userId;
            console.log(body);
            console.log(user_id);
            if (user_id !== undefined){
                consumer.subscriptions.create({ channel: "AdministrationChannel", user_id: user_id}, {
                    connected() {
                        // alert("CONECTADO!!!");
                    },

                    disconnected() {
                        // Called when the subscription has been terminated by the server
                    },

                    received(data) {
                        console.log(data);
                        switch (data.type) {
                            case 'progress':
                                let progress_area = $('#progress_area');

                                if(progress_area.length > 0){
                                    let info_text = progress_area.find('.info-message');
                                    let progress_bar = progress_area.find('.progress-bar');

                                    if(data.percent === 100){
                                        progress_area[0].classList.remove('active');
                                    }else{
                                        progress_area[0].classList.add('active');
                                        if(info_text.length > 0){
                                            info_text[0].innerHTML = data.message;
                                        }
                                        if(progress_bar.length > 0){
                                            progress_bar[0].setAttribute('aria-valuenow', data.percent);
                                            progress_bar[0].innerHTML = data.percent + '%';
                                            progress_bar[0].style.width = data.percent+ '%' ;
                                            progress_bar[0].classList.add(`bg-${data.color}`) ;
                                        }
                                    }
                                }
                                break;
                            case 'toastr':
                                toastr[data.color](data.message)
                                break;
                        }
                    }
                });
            }

        }
    }
});
